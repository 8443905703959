export const checkout = {
  state: {
    DE:{
      couponInfo:'Gutscheincodes können im nächsten Schrittt hinzugefügt werden.',
      VATInfo:'Umsatzsteuer ID kann im nächsten Schritt hinzugefügt werden.',
      couponCode:'Gutscheincode',
      checkInput:'Überprüfe Eingabe..',
      validateCode:'Code einlösen',
      vatID:'Umsatzsteuer ID',
      validateVatID:'Ust. ID prüfen',
      subtotal:'Zwischensumme',
      shipping:'Versand',
      noShipping:'Versand noch nicht ausgewählt',
      discount:'Rabatt',
      total:'Gesamt',
      totalTaxes:'Inkl. 19% Ust.',
      taxInfo:'Alle Preise inkl. 19% MwSt.',

      step01:'Für wen ist diese Bestellung?',
      email:'Email Adresse',
      cc:'CC für Bestellungen / Rechnungen',
      firstName:'Vorname',
      lastName:'Nachname',
      step01Info:'*Firmendaten kannst du im nächsten Schritt eingeben',
      continueGuest:'Als Gast weiter',
      next:'Weiter',
      applyStock:'Artikelmengen anpassen',
      login:'Einloggen / Registrieren',
      backToCart:'Zurück zum Warenkorb',

      step02:'Wie möchtest du deine Bestellung erhalten?',
      pickupDetails:'Abholung (Kronsaalsweg 70, Hamburg)',
      exw:'Export / EXW',
      nextShippingAdd:'Weiter zur Lieferadresse',
      back:'Zurück',

      step03:'Wohin soll deine Bestellung geschickt werden?',
      companyName:'Firmenname',
      street1:'Straße und Hausnummer',
      street2:'Zusätzliche Adresse',
      zip:'Postleitzahl',
      city:'Ort',
      cityArea:'Stadt Gebiet',
      region:'Region',
      alternativeAdd:'Abweichende Rechnungsadresse?',
      nextShipping:'Weiter zum Versand',
      nextBilling:'Weiter zur Rechnungsadresse',

      step04:'Welche Adresse soll auf der Rechnung stehen?',

      step05:'Wähle eine Versandoption',
      step05b2b:'Die Versandkosten können je nach gewählter Verpackung im Nachhinein variieren.',
      noShipping:'Ups, unser System hat keine passende Versandoption gefunden. Das kann passieren, wenn du sehr viel bestellst oder es bei einem Produkt einen internen Fehler gibt. Schick uns am besten schnell eine Email an hello@rewhite-climbing.de mit einem Screenshot aus deinem Warenkorb, dann legen wir deine Bestellung manuell an. Danke!',
      to:'bis',
      days:'Werktage',

      step06:'Ist das Okay für dich?',
      step06Info:'Wir packen dir nur ins Paket, was du auch wirklich möchtest.',
      newsletter:'Ich möchte euren Newsletter bekommen.',
      goodies:'Ich würde mich über Goodies freuen.',
      flyer:'Flyer und Co. könnt ihr gerne dazulegen.',
      nextSummary:'Bestellzusammenfassung anzeigen',

      step07:'Überprüfe deine Bestellung',
      contact:'Kontakt',
      shippingAddress:'Lieferadresse',
      billingAddress:'Rechnungsadresse',
      kron:'Kronsaalsweg 70-74, Eingang C',
      kron2:'22525, Hamburg',
      exwInfo:'Du beauftragst einen Spediteur deiner Wahl',
      shippingCost:'Versandkosten',
      environment: 'Umwelt',
      trees1sing: 'Mit dieser Bestellung wird',
      trees2sing: 'Baum in Indonesien gepflanzt.',
      trees1plur:'Mit dieser Bestellung werden',
      trees2plur:'Bäume in Indonesien gepflanzt.',
      redirect:'Du wirst sofort weitergeleitet',
      payNow:'Jetzt bezahlen & bestellen',
      payLater:'Auf Rechnung bezahlen & bestellen',
      payAtPickup:'Bei Abholung zahlen & bestellen',

      errorStock:'Einige Artikel sind nicht mehr in ausreichender Menge vorhanden.',
      errorCoupon:'Bitte gib erst deine Email Adresse an, damit wir den Gutschein zuordnen können.',
      errorService:'Bitte wähle eine Zustellmethode aus.',
      errorVatID:'Eine Ust. ID kann nur bei Käufen außerhalb Deutschlands angegeben werden.',
      errorVatID2:'Die Ust. ID ist gültig, die MwSt. in deinem Warenkorb wurde angepasst.',
      errorVatID3:'Die Ust. ID ',
      errorVatID4:' ist nicht gültig.',
      errorVatID5:'Bitte gib erst deine Email Adresse an, damit wir die Ust. ID zuordnen können.',
      errorShippingMethod:'Bitte wähle eine Versandmethode aus.',

      mollieInfo:'Dein Einkauf bei rewhite-climbing.de',

      re:'re:',
      orderProcessing:'Deine Bestellung wird gerade verarbeitet..',
      orderCompleted:'Bestellung abgeschlossen',
      thankYou:'Danke für deine Bestellung.',
      orderInfo:'Du erhälst in Kürze deine Bestellbestätigung und Rechnung per Email.',
      backHome:'Zurück zum Shop'
    },
    EN:{
      couponInfo:'Coupon codes can be added in the next step.',
      VATInfo:'VAT ID can be added in the next step.',
      couponCode:'Discount Code',
      checkInput:'Validating data..',
      validateCode:'Validate Code',
      vatID:'VAT ID',
      validateVatID:'Check VAT ID',
      subtotal:'Subtotal',
      shipping:'Shipping',
      noShipping:'No shipping method selected',
      discount:'Discount',
      total:'Total',
      totalTaxes:'Incl. 19% taxes',
      taxInfo:'All prices including 19% taxes',

      step01:'Who is placing this order?',
      email:'Email address',
      cc:'CC for orders / invoices',
      firstName:'First name',
      lastName:'Last name',
      step01Info:'*Company data can be entered in the next steps',
      continueGuest:'Continue as guest',
      next:'Next',
      applyStock:'Adjust quantities',
      login:'Login / Register',
      backToCart:'Back to cart',

      step02:'How would you like to receive your order?',
      pickupDetails:'Pickup (Kronsaalsweg 70, Hamburg)',
      exw:'Export / EXW',
      nextShippingAdd:'Continue to shipping address',
      back:'Back',

      step03:'Where can we ship your order to?',
      companyName:'Company name',
      street1:'Street and house number',
      street2:'Additional address',
      zip:'Postal code',
      city:'City',
      cityArea:'City Area',
      region:'Region',
      alternativeAdd:'Alternative billing address?',
      nextShipping:'Continue to shipping',
      nextBilling:'Continue to billing address',

      step04:'Which address should be printed on the invoice?',

      step05:'Pick a shipping method',
      step05b2b:'Shipping costs may vary depending on the chosen packaging.',
      noShipping:'Oops, our system has not found any available shipping mehtod. That can happen if you order lager amounts or if there is an internal error with a product. Please, send us an email to hello@rewhite-climbing.de with a screenshop of your cart and we will help you placing that order quickly. Thanks!',
      to:'to',
      days:'working days',

      step06:'Is that okay for you?',
      step06Info:'We only add to your parcel what you really like to receive',
      newsletter:'I would like to receive your newsletter.',
      goodies:'I am happy to receive some goodies.',
      flyer:'Flyer and sticker are great.',
      nextSummary:'Show order summary',

      step07:'Check your order',
      contact:'Contact',
      shippingAddress:'Shipping address',
      billingAddress:'Billing address',
      kron:'Kronsaalsweg 70-74, Entry C',
      kron2:'22525, Hamburg',
      exwInfo:'You will contact a freight forwarder of your choice',
      shippingCost:'Shipping costs',
      environment: 'Environment',
      trees1sing: 'With this order',
      trees2sing: 'tree will be planted in Indonesia.',
      trees1plur:'With this order',
      trees2plur:'trees will be planted in Indonesia.',
      redirect:'You will be redirected shortly',
      payNow:'Pay now & place order',
      payLater:'Pay on account & place order',
      payAtPickup:'Pay at pickup & place order',

      errorStock:'Some items are no longer available in sufficient quantities.',
      errorCoupon:'Please enter your email address first so that we can assign the voucher.',
      errorService:'Please choose a delivery method.',
      errorVatID:'A VAT ID can only be entered from outside of Germany',
      errorVatID2:'Your VAT ID is valid, taxes for your order got adjusted.',
      errorVatID3:'Your VAT ID ',
      errorVatID4:' is not valid.',
      errorVatID5:'Please enter your email address first so that we can assign your VAT ID.',
      errorShippingMethod:'Please choose a delivery method',

      mollieInfo:'Your purchase at rewhite-climbing.de' ,

      re:'re:',
      orderProcessing:'Your order is being processed..',
      orderCompleted:'Order completed',
      thankYou:'Thanks for your order.',
      orderInfo:'You will receive an order confirmation and invoice shortly.',
      backHome:'Back to shop'
    }
  }
};
