<template>
  <div class="font-stolzl text-coolGray-900 sm:flex sm:flex-row sm:justify-center sm:items-center sm:w-screen sm:h-screen">

    <!-- LEFT -->
    <div v-if="!fetchMollie && !checkoutCompleted" class="w-full md:w-1/2 h-screen md:flex flex-col" :class="{'hidden' : !showCartInCheckout}">
        <div class=" w-full min-h-full p-2 flex flex-col bg-coolGray-100 pb-32 md:pb-0">
          <div class="h-auto flex-grow overflow-y-auto flex flex-col">
            <cartLines />
          </div>
          <transition @enter="flowEnter" @leave="flowLeave" :css="false" mode="out-in">
            <div v-if="currentStep < 6" class="h-auto py-10 border-t border-coolGray-300 mx-8">

            <!-- COUPON CODES-->
            <div v-if="currentStep > 0">
              <form id="checkoutCoupon" @submit.prevent="submitCouponCode" class="flex flex-col md:flex-row gap-2 justify-center items-center w-full">

                <!-- coupon code field -->
                <div class="relative bg-white md:mr-1.5 px-5 h-16 font-stolzl font-light w-full md:w-3/5 text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                  <transition @enter="showInfo" @leave="hideInfo" :css="false">
                    <div v-if="couponCode && couponCode !== ''" class="font-light text-coolGray-700 text-xs">{{text.couponCode}}</div>
                  </transition>
                  <input id="couponCode" name="couponCode" v-model="couponCode" type="text" :placeholder="text.couponCode" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
                </div>

                <input type="submit"
                  :value="[addCouponIsFetching ? text.checkInput : text.validateCode]"
                  class="transition duration-500 ease-in-out w-full md:w-2/5 bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
                  :class="{'animate-pulse' : addCouponIsFetching}"/>

              </form>

              <div v-if="couponCodeErrors?.length > 0" class="mt-2 font-light text-base text-red-600">
                {{couponCodeErrors}}
              </div>

            </div>
            <div v-if="currentStep == 0" class="font-light mt-5 text-sm">{{text.couponInfo}}</div>

            <!-- VAT ID-->
            <div v-if="channel == 'b2b' && currentStep > 0">
              <form v-if="!metaVatId" id="checkoutVAT" @submit.prevent="submitVatId" class="mt-4 flex flex-col md:flex-row gap-2 justify-center items-center w-full">

                <!-- VAT id field -->
                <div class="relative bg-white md:mr-1.5 px-5 h-16 font-stolzl font-light w-full md:w-3/5 text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                  <transition @enter="showInfo" @leave="hideInfo" :css="false">
                    <div v-if="vatId && vatId !== ''" class="font-light text-coolGray-700 text-xs">{{text.vatID}}</div>
                  </transition>
                  <input id="vatId" name="vatId" v-model="vatId" type="text" :placeholder="text.vatID" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
                </div>

                <input type="submit"
                  :value="[vatIdIsFetching ? text.checkInput : text.validateVatID]"
                  class="transition duration-500 ease-in-out w-full md:w-2/5 bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
                  :class="{'animate-pulse' : vatIdIsFetching}"/>

              </form>

              <div v-if="vatIdErrors?.length > 0" class="mt-2 font-light text-base text-red-600">
                {{vatIdErrors}}
              </div>
            </div>
            <div v-else-if="channel == 'b2b' && currentStep == 0" class="font-light mt-5 text-sm">{{text.VATInfo}}</div>

            <!-- CART TOTAL-->
            <div class=" font-light mt-5 text-sm">

              <!-- zwischensumme -->
              <div class="flex flex-row w-full justify-between items-center">
                <div>{{text.subtotal}}</div>
                <div>{{cartTotal.total}}</div>
              </div>

              <!-- versand -->
              <div class="flex flex-row w-full justify-between items-center mt-1">
                <div>{{text.shipping}}</div>
                <div>{{formatPrice(shippingCost) || text.noShipping}}</div>
              </div>

              <!-- rabatt -->
              <div v-if="discountCode && discountAmount" class="flex flex-row w-full justify-between items-center mt-1" :class="{'animate-pulse' : removeCouponIsFetching}">
                <div>{{text.discount}}</div>
                <div class="flex flex-row justify-center items-center">
                  <div class="mr-2">{{text.couponCode}} "{{discountCode}}"</div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-coolGray-900 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="removeCouponCode()">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />
                </svg>
                </div>

                <div>-{{formatPrice(discountAmount)}}</div>
              </div>

              <!-- gesamt ohne steuern-->
              <div class="flex flex-row w-full justify-between items-center mt-1">
                <div>{{text.total}}</div>
                <div v-if="checkoutAmount">{{formatPrice(checkoutAmount)}}</div>
                <div v-else>{{cartTotal.total}}</div>
              </div>

              <!-- gesamt mit steuern -->
              <div v-if="channel == 'b2b' && checkoutTotalIncludesTaxes" class="flex flex-row w-full justify-between items-center mt-1">
                <div>{{text.totalTaxes}}</div>
                <div>{{formatPrice(checkoutAmountWithTaxes)}}</div>
              </div>

              <!-- mwst -->
              <div v-if="channel == 'b2c'" class="font-light text-xs text-coolGray-700 mt-1">{{text.taxInfo}}</div>

            </div>
          </div>
          </transition>
        </div>
      </div>
    <!-- LEFT -->
    <div v-else class="w-full md:w-1/2 h-screen hidden md:flex flex-row justify-center items-center bg-coolGray-100">
      <div class="font-bold text-9xl">{{text.re}}</div>
    </div>

    <!-- RIGHT -->
    <div v-if="!fetchMollie && !checkoutCompleted" class="w-full md:w-1/2 h-auto min-h-screen md:h-full mt-5 md:mt-0 flex flex-col md:flex-row justify-center items-center" :class="{'hidden' : showCartInCheckout}">

      <transition @enter="flowEnter" @leave="flowLeave" :css="false" mode="out-in">

        <!-- STEP 01 USER DATA / LOGIN-->
        <div v-if="currentStep == 0" class="w-5/6 md:w-9/12">
          <form id="checkout01" @submit.prevent="submitStep01">
            <div class="text-xl">{{text.step01}}</div>

            <!-- email -->
            <div class="relative mt-5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
              <transition @enter="showInfo" @leave="hideInfo" :css="false">
                <div v-if="customerEmail && customerEmail !== ''" class="font-light text-coolGray-700 text-xs">{{text.email}}</div>
              </transition>
              <input id="customerEmail" name="customerEmail" v-model="customerEmail" type="email" :placeholder="text.email" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
            </div>

            <!-- cc -->
            <div v-if="channel == 'b2b'" class="relative mt-5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
              <transition @enter="showInfo" @leave="hideInfo" :css="false">
                <div v-if="customerEmailCC && customerEmailCC !== ''" class="font-light text-coolGray-700 text-xs">{{text.cc}}</div>
              </transition>
              <input id="customerEmailCC" name="customerEmailCC" v-model="customerEmailCC" type="email" :placeholder="text.cc" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center"/>
            </div>

            <div class="flex flex-row">

              <!-- shippingFirstName -->
              <div class="relative mt-3 mr-1.5 px-5 h-16 font-stolzl font-light w-1/2 text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="shippingFirstName && shippingFirstName !== ''" class="font-light text-coolGray-700 text-xs">{{text.firstName}}</div>
                </transition>
                <input id="shippingFirstName" name="shippingFirstName" v-model="shippingFirstName" type="text" :placeholder="text.firstName" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
              </div>

              <!-- shippingLastName -->
              <div class="relative mt-3 ml-1.5 px-5 h-16 font-stolzl font-light w-1/2 text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="shippingLastName && shippingLastName !== ''" class="font-light text-coolGray-700 text-xs">{{text.lastName}}</div>
                </transition>
                <input id="shippingLastName" name="shippingLastName" v-model="shippingLastName" type="text" :placeholder="text.lastName" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
              </div>

            </div>

            <div class="mt-2 text-sm font-light">{{text.step01Info}}</div>
            <div v-if="!updateInsufficientStock">
              <input v-if="channel == 'b2b' && !auth" type="submit"
                :value="[createCheckoutisFetching ? text.checkInput : text.next]"
                class="mt-5 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
                :class="{'animate-pulse' : createCheckoutisFetching}"
                @click="direction = 1"/>
              <input v-else type="submit"
                :value="[createCheckoutisFetching ? text.checkInput : text.next]"
                class="mt-5 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
                :class="{'animate-pulse' : createCheckoutisFetching}"
                @click="direction = 1"/>
            </div>
            <div v-else>
              <input type="submit"
                :value="[createCheckoutisFetching ? text.checkInput : text.applyStock]"
                class="mt-5 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
                :class="{'animate-pulse' : createCheckoutisFetching}"
                @click="direction = 1"/>
            </div>

          </form>

          <div v-if="step01Errors?.length > 0" class="mt-2 font-light text-base text-red-600">
            {{step01Errors}}
          </div>

          <router-link :to="{name: 'Cart'}" class="hidden font-stolzl md:flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.backToCart}}</div>
          </router-link>

        </div>
        <!-- STEP 01 -->

        <!-- STEP 02 SHIPPING OR PICKUP OR EXW-->
        <div v-else-if="currentStep == 1" class="w-5/6 md:w-9/12">
          <form id="checkout02" @submit.prevent="submitStep02">
            <div class="text-xl">{{text.step02}}</div>

            <div class="cursor-pointer mt-5 px-5 appearance-none outline-none placeholder-coolGray-900 h-16 font-stolzl font-light w-full text-coolGray-900 flex flex-row justify-start items-center border-2 border-coolGray-900" @click="selectedService = 'versand'">
              <input type="radio" id="versand" value="versand" v-model="selectedService" class="mr-2 appearance-none w-3 h-3 rounded-full ring-1 ring-offset-2 ring-coolGray-900 bg-white checked:bg-coolGray-900">
              {{text.shipping}}
            </div>

            <div class="cursor-pointer mt-3 px-5 appearance-none outline-none placeholder-coolGray-900 h-16 font-stolzl font-light w-full text-coolGray-900 flex flex-row justify-start items-center border-2 border-coolGray-900" @click="selectedService = 'abholung'">
              <input type="radio" id="abholung" value="abholung" v-model="selectedService" class="mr-2 appearance-none w-3 h-3 rounded-full ring-1 ring-offset-2 ring-coolGray-900 bg-white checked:bg-coolGray-900">
              {{text.pickupDetails}}
            </div>

            <div v-if="channel == 'b2b'" class="cursor-pointer mt-3 px-5 appearance-none outline-none placeholder-coolGray-900 h-16 font-stolzl font-light w-full text-coolGray-900 flex flex-row justify-start items-center border-2 border-coolGray-900" @click="selectedService = 'exw'">
              <input type="radio" id="exw" value="exw" v-model="selectedService" class="mr-2 appearance-none w-3 h-3 rounded-full ring-1 ring-offset-2 ring-coolGray-900 bg-white checked:bg-coolGray-900">
              {{text.exw}}
            </div>

            <input v-if="selectedService == 'versand'" type="submit" :value="text.nextShippingAdd" class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 border-2 border-coolGray-900" @click="direction = 1" />

            <input v-else type="submit"
              :value="[setShippingMethodIsFetching ? text.checkInput : text.next]"
              class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
              :class="{'animate-pulse' : setShippingMethodIsFetching}"
              @click="direction = 4" />

          </form>

          <div v-if="step02Errors?.length > 0" class="mt-2 font-light text-base text-red-600">
            {{step02Errors}}
          </div>

          <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="goBack()">
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back}}</div>
          </div>

        </div>
        <!-- STEP 02 -->

        <!-- STEP 03 SHIPPING DETAILS-->
        <div v-else-if="currentStep == 2" class="w-5/6 md:w-9/12">
          <form id="checkout03" @submit.prevent="submitStep03">
            <div class="text-xl">{{text.step03}}</div>

            <!-- shippingCompanyName -->
            <div v-if="validation.addressValidationRules.allowedFields.includes('companyName')" class="relative mt-5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
              <transition @enter="showInfo" @leave="hideInfo" :css="false">
                <div v-if="shippingCompanyName && shippingCompanyName !== ''" class="font-light text-coolGray-700 text-xs">{{text.companyName}}</div>
              </transition>
              <input id="shippingCompanyName" name="shippingCompanyName" v-model="shippingCompanyName" type="text" :placeholder="text.companyName" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('companyName') ? true : false"/>
            </div>

            <!-- shippingStreet1 -->
            <div v-if="validation.addressValidationRules.allowedFields.includes('streetAddress1')" class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
              <transition @enter="showInfo" @leave="hideInfo" :css="false">
                <div v-if="shippingStreetAddress1 && shippingStreetAddress1 !== ''" class="font-light text-coolGray-700 text-xs">{{text.street1}}</div>
              </transition>
              <input id="shippingStreetAddress1" name="shippingStreetAddress1" v-model="shippingStreetAddress1" type="text" :placeholder="text.street1" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('streetAddress1') ? true : false"/>
            </div>

            <!-- shippingStreet2 -->
            <div  v-if="validation.addressValidationRules.allowedFields.includes('streetAddress2')" class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
              <transition @enter="showInfo" @leave="hideInfo" :css="false">
                <div v-if="shippingStreetAddress2 && shippingStreetAddress2 !== ''" class="font-light text-coolGray-700 text-xs">{{text.street2}}</div>
              </transition>
              <input id="shippingStreetAddress2" name="shippingStreetAddress2" v-model="shippingStreetAddress2" type="text" :placeholder="text.street2" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('streetAddress2') ? true : false"/>
            </div>

            <div class="flex flex-row gap-3">
              <!-- shippingPostalCode -->
              <div v-if="validation.addressValidationRules.allowedFields.includes('postalCode')" class="flex-grow relative mt-3 px-5 h-16 font-stolzl font-light text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="shippingPostalCode && shippingPostalCode !== ''" class="font-light text-coolGray-700 text-xs">{{text.zip}}</div>
                </transition>
                <input id="shippingPostalCode" name="shippingPostalCode" v-model="shippingPostalCode" type="text" :placeholder="text.zip" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('postalCode') ? true : false"/>
              </div>

              <!-- shippingCity -->
              <div v-if="validation.addressValidationRules.allowedFields.includes('city')" class="flex-grow relative mt-3 px-5 h-16 font-stolzl font-light text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="shippingCity && shippingCity !== ''" class="font-light text-coolGray-700 text-xs">{{text.city}}</div>
                </transition>
                <input id="shippingCity" name="shippingCity" v-model="shippingCity" type="text" :placeholder="text.city" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('city') ? true : false"/>
              </div>
            </div>

            <div class="flex flex-row gap-3">
              <!-- shippingCityArea -->
              <div v-if="validation.addressValidationRules.allowedFields.includes('cityArea')" class="flex-grow relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="shippingCityArea && shippingCityArea !== ''" class="font-light text-coolGray-700 text-xs">{{text.cityArea}}</div>
                </transition>
                <input id="shippingCityArea" name="shippingCityArea" v-model="shippingCityArea" type="text" :placeholder="text.cityArea" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('cityArea') ? true : false"/>
              </div>

              <!-- shippingCountryArea -->
              <div v-if="validation.addressValidationRules.allowedFields.includes('countryArea')" class="flex-grow relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="shippingCountryArea && shippingCountryArea !== ''" class="font-light text-coolGray-700 text-xs">{{text.region}}</div>
                </transition>
                <input id="shippingCountryArea" name="shippingCountryArea" v-model="shippingCountryArea" type="text" :placeholder="text.region" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('countryArea') ? true : false"/>
              </div>
            </div>

            <!-- shippingCountry -->
            <div class="relative mt-3 mr-1.5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
              <select v-model="shippingCountry" class="w-full font-light outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required>
                <option v-for="shippingCountry in countries" :key="shippingCountry" :value="shippingCountry.value">
                  {{ translatedCountries[shippingCountry.name] }}
                </option>
              </select>
            </div>

            <!-- alternativeBilling -->
            <div class="cursor-pointer mt-3 px-5 appearance-none outline-none placeholder-coolGray-900 h-16 font-stolzl font-light w-full text-coolGray-900 flex flex-row justify-start items-center border-2 border-coolGray-900" @click="abweichendeAdresse = !abweichendeAdresse">
            <input type="checkbox" id="abweichendeAdresse" value="abweichendeAdresse" v-model="abweichendeAdresse" class="mr-2 appearance-none w-3 h-3 rounded-full ring-1 ring-offset-2 ring-coolGray-900 bg-white checked:bg-coolGray-900">
              {{text.alternativeAdd}}
            </div>

            <input v-if="!abweichendeAdresse" type="submit"
              :value="[updateShippingIsFetching || updateBillingIsFetching ? text.validateInput : text.nextShipping]"
              class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
              :class="{'animate-pulse' : updateShippingIsFetching || updateBillingIsFetching}"
              @click="direction = 2"/>
            <input v-else type="submit"
              :value="[updateShippingIsFetching || updateBillingIsFetching ? text.validateInput : text.nextBilling]"
              class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
              :class="{'animate-pulse' : updateShippingIsFetching || updateBillingIsFetching }"
              @click="direction = 1"/>
          </form>

          <div v-if="step03Errors?.length > 0" class="mt-2 font-light text-base text-red-600">
            {{step03Errors}}
          </div>

          <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="goBack()">
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back}}</div>
          </div>

        </div>
        <!-- STEP 03 -->

        <!-- STEP 04 BILLING DETAILS-->
        <div v-else-if="currentStep == 3" class="w-5/6 md:w-9/12">
          <form id="checkout04" @submit.prevent="submitStep04">
            <div class="text-xl">{{text.step04}}</div>

            <div class="flex flex-row gap-3 mt-5">
              <!-- billingFirstName -->
              <div class="flex-grow relative px-5 h-16 font-stolzl font-light text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="billingFirstName && billingFirstName !== ''" class="font-light text-coolGray-700 text-xs">{{text.firstName}}</div>
                </transition>
                <input id="billingFirstName" name="billingFirstName" v-model="billingFirstName" type="text" :placeholder="text.firstName" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center"/>
              </div>

              <!-- billingLastName -->
              <div class="flex-grow relative px-5 h-16 font-stolzl font-light text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="billingLastName && billingLastName !== ''" class="font-light text-coolGray-700 text-xs">{{text.lastName}}</div>
                </transition>
                <input id="billingLastName" name="billingLastName" v-model="billingLastName" type="text" :placeholder="text.lastName" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required>
              </div>
            </div>

            <!-- billingCompanyName -->
            <div v-if="validation.addressValidationRules.allowedFields.includes('companyName')" class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
              <transition @enter="showInfo" @leave="hideInfo" :css="false">
                <div v-if="billingCompanyName && billingCompanyName !== ''" class="font-light text-coolGray-700 text-xs">{{text.companyName}}</div>
              </transition>
              <input id="billingCompanyName" name="billingCompanyName" v-model="billingCompanyName" type="text" :placeholder="text.companyName" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('companyName') ? true : false"/>
            </div>

            <!-- billingStreet1 -->
            <div v-if="validation.addressValidationRules.allowedFields.includes('streetAddress1')" class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
              <transition @enter="showInfo" @leave="hideInfo" :css="false">
                <div v-if="billingStreetAddress1 && billingStreetAddress1 !== ''" class="font-light text-coolGray-700 text-xs">{{text.street1}}</div>
              </transition>
              <input id="billingStreetAddress1" name="billingStreetAddress1" v-model="billingStreetAddress1" type="text" :placeholder="text.street1" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('streetAddress1') ? true : false"/>
            </div>

            <!-- billingStreet2 -->
            <div  v-if="validation.addressValidationRules.allowedFields.includes('streetAddress2')" class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
              <transition @enter="showInfo" @leave="hideInfo" :css="false">
                <div v-if="billingStreetAddress2 && billingStreetAddress2 !== ''" class="font-light text-coolGray-700 text-xs">{{text.street2}}</div>
              </transition>
              <input id="billingStreetAddress2" name="billingStreetAddress2" v-model="billingStreetAddress2" type="text" :placeholder="text.street2" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('streetAddress2') ? true : false"/>
            </div>

            <div class="flex flex-row gap-3">
              <!-- billingPostalCode -->
              <div v-if="validation.addressValidationRules.allowedFields.includes('postalCode')" class="flex-grow relative mt-3 px-5 h-16 font-stolzl font-light text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="billingPostalCode && billingPostalCode !== ''" class="font-light text-coolGray-700 text-xs">{{text.zip}}</div>
                </transition>
                <input id="billingPostalCode" name="billingPostalCode" v-model="billingPostalCode" type="text" :placeholder="text.zip" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('postalCode') ? true : false"/>
              </div>

              <!-- billingCity -->
              <div v-if="validation.addressValidationRules.allowedFields.includes('city')" class="flex-grow relative mt-3 px-5 h-16 font-stolzl font-light text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="billingCity && billingCity !== ''" class="font-light text-coolGray-700 text-xs">{{text.city}}</div>
                </transition>
                <input id="billingCity" name="billingCity" v-model="billingCity" type="text" :placeholder="text.city" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('city') ? true : false"/>
              </div>
            </div>

            <div class="flex flex-row gap-3">
              <!-- billingCityArea -->
              <div v-if="validation.addressValidationRules.allowedFields.includes('cityArea')" class="flex-grow relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="billingCityArea && billingCityArea !== ''" class="font-light text-coolGray-700 text-xs">{{text.cityArea}}</div>
                </transition>
                <input id="billingCityArea" name="billingCityArea" v-model="billingCityArea" type="text" :placeholder="text.cityArea" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('cityArea') ? true : false"/>
              </div>

              <!-- billingCountryArea -->
              <div v-if="validation.addressValidationRules.allowedFields.includes('countryArea')" class="flex-grow relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="billingCountryArea && billingCountryArea !== ''" class="font-light text-coolGray-700 text-xs">{{text.region}}</div>
                </transition>
                <input id="billingCountryArea" name="billingCountryArea" v-model="billingCountryArea" type="text" :placeholder="text.region" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" :required="validation.addressValidationRules.requiredFields.includes('countryArea') ? true : false"/>
              </div>
            </div>

            <!-- billingCountry -->
            <div class="relative mt-3 mr-1.5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
              <select v-model="billingCountry" class="w-full font-light outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required>
                <option v-for="billingCountry in countries" :key="billingCountry" :value="billingCountry.value">
                  {{ translatedCountries[billingCountry.name] }}
                </option>
              </select>
            </div>

            <input type="submit"
              :value="[updateBillingIsFetching ? text.checkInput : text.nextShipping]"
              class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
              :class="{'animate-pulse' : updateBillingIsFetching}"
              @click="direction = 1"/>
          </form>

          <div v-if="step04Errors?.length > 0" class="mt-2 font-light text-base text-red-600">
            {{step04Errors}}
          </div>

          <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="goBack()">
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back}}</div>
          </div>

        </div>
        <!-- STEP 04 -->

        <!-- STEP 05 SHIPPING OTIONS -->
        <div v-else-if="currentStep == 4" class="w-5/6 md:w-9/12">
          <form id="checkout05" @submit.prevent="submitStep05">
            <div class="text-xl">{{text.step05}}</div>
            <div v-if="channel == 'b2b'" class="text-red-600 mt-2 font-light text-base">{{text.step05b2b}}</div>

            <!-- shippingMethods -->
            <div v-if="availableShippingMethods">
              <div v-for="(method,index) in availableShippingMethods" :key="method">
                <div class="cursor-pointer mt-5 px-5 appearance-none outline-none placeholder-coolGray-900 h-16 font-stolzl font-light w-full text-coolGray-900 flex flex-row justify-start items-center border-2 border-coolGray-900" @click="selectedShippingMethod = method.id, selectedShippingMethodName = method.name">

                  <input type="radio" :id="method.name"
                    :value="method.id" v-model="selectedShippingMethod"
                    class="mr-2 appearance-none w-3 h-3 rounded-full ring-1 ring-offset-2 ring-coolGray-900 bg-white checked:bg-coolGray-900"
                    :required="index == 0 ? true : false"/>

                  <div class="mr-5">{{method.name}}</div>
                  <div>{{formatPrice(method.price.amount)}}</div>
                  <div v-if="method.minimumDeliveryDays" class="ml-5 text-sm">({{method.minimumDeliveryDays}} {{text.to}} {{method.maximumDeliveryDays}} {{text.days}})</div>
                </div>
              </div>
              <div v-if="availableShippingMethods.length == 0" class="font-light my-2 text-sm">{{text.noShipping}}</div>
            </div>

          <input type="submit"
            :value="[setShippingMethodIsFetching ? text.checkInput : text.next]"
            class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
            :class="{'animate-pulse' : setShippingMethodIsFetching}"
            @click="direction = 1"/>

          </form>

          <div v-if="step05Errors?.length > 0" class="mt-2 font-light text-base text-red-600">
            {{step05Errors}}
          </div>

          <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="goBack()">
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back}}</div>
          </div>

        </div>
        <!-- STEP 05 -->

        <!-- STEP 06 MARKETING -->
        <div v-else-if="currentStep == 5" class="w-5/6 md:w-9/12">
          <form id="checkout06" @submit.prevent="submitStep06">
            <div class="text-xl">{{text.step06}}</div>
            <div class="mt-2 text-sm font-light">{{text.step06Info}}</div>


            <div class="cursor-pointer mt-5 px-5 appearance-none outline-none placeholder-coolGray-900 h-16 font-stolzl font-light w-full text-coolGray-900 flex flex-row justify-start items-center border-2 border-coolGray-900" @click="emailMarketing = !emailMarketing">
              <input type="checkbox" id="emailMarketing" v-model="emailMarketing"
                class="mr-2 appearance-none w-3 h-3 rounded-full ring-1 ring-offset-2 ring-coolGray-900 bg-white checked:bg-coolGray-900"/>
              <label for="emailMarketing" @click="emailMarketing = !emailMarketing" class="cursor-pointer">{{text.newsletter}}</label>
            </div>

            <div class="cursor-pointer mt-3 px-5 appearance-none outline-none placeholder-coolGray-900 h-16 font-stolzl font-light w-full text-coolGray-900 flex flex-row justify-start items-center border-2 border-coolGray-900" @click="acceptsGoodies = !acceptsGoodies">
              <input type="checkbox" id="acceptsGoodies" v-model="acceptsGoodies"
                class="mr-2 appearance-none w-3 h-3 rounded-full ring-1 ring-offset-2 ring-coolGray-900 bg-white checked:bg-coolGray-900"/>
              <label for="acceptsGoodies" @click="acceptsGoodies = !acceptsGoodies" class="cursor-pointer">{{text.goodies}}</label>
            </div>

            <div class="cursor-pointer mt-3 px-5 appearance-none outline-none placeholder-coolGray-900 h-16 font-stolzl font-light w-full text-coolGray-900 flex flex-row justify-start items-center border-2 border-coolGray-900" @click="marketingMaterial = !marketingMaterial">
              <input type="checkbox" id="marketingMaterial" v-model="marketingMaterial"
                class="mr-2 appearance-none w-3 h-3 rounded-full ring-1 ring-offset-2 ring-coolGray-900 bg-white checked:bg-coolGray-900"/>
              <label for="marketingMaterial" @click="marketingMaterial = !marketingMaterial" class="cursor-pointer">{{text.flyer}}</label>
            </div>


            <input type="submit" :value="text.nextSummary"
            class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
            @click="direction = 1"/>

          </form>

          <div v-if="step06Errors?.length > 0" class="mt-2 font-light text-base text-red-600">
            {{step06Errors}}
          </div>

          <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="goBack()">
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back}}</div>
          </div>

        </div>
        <!-- STEP 06  -->

        <!-- STEP 07 ORDER SUMMARY -->
        <div v-else-if="currentStep == 6" class="w-5/6 md:w-9/12">
          <form id="checkout07" @submit.prevent="submitStep07">
            <div class="text-xl">{{text.step07}}</div>

            <!-- kontakt -->
            <div class="font-light mt-5">
              <div class="text-coolGray-400 text-sm">{{text.contact}}</div>
              <div class="font-light">{{customerEmail}}</div>
              <div v-if="selectedService == 'abholung'" class="flex flex-row gap-1">
                <div v-if="shippingFirstName">{{shippingFirstName}}</div>
                <div v-if="shippingLastName">{{shippingLastName}}</div>
              </div>
            </div>

            <!-- adressen -->
            <div v-if="selectedService !== 'abholung'" class="mt-5 flex flex-row justify-between font-light">
              <div class="w-1/2">
                <div class="text-coolGray-400 text-sm">{{text.shippingAddress}}</div>
                <div class="flex flex-col">
                  <div class="flex flex-row gap-1">
                    <div v-if="shippingFirstName">{{shippingFirstName}}</div>
                    <div v-if="shippingLastName">{{shippingLastName}}</div>
                  </div>
                  <div v-if="shippingCompanyName">{{shippingCompanyName}}</div>
                  <div v-if="shippingStreetAddress1 && shippingStreetAddress1 !== 'EMPTY'">{{shippingStreetAddress1}}</div>
                  <div v-if="shippingStreetAddress2">{{shippingStreetAddress2}}</div>
                  <div class="flex flex-row gap-1">
                    <div v-if="shippingPostalCode && shippingPostalCode !== '12345'">{{shippingPostalCode}}</div>
                    <div v-if="shippingCity && shippingCity !== 'EMPTY'">{{shippingCity}}</div>
                  </div>
                  <div v-if="shippingCityArea">{{shippingCityArea}}</div>
                  <div v-if="shippingCountry">{{shippingCountry}}</div>
                  <div v-if="shippingCountryArea">{{shippingCountryArea}}</div>
                </div>
              </div>
              <div v-if="abweichendeAdresse" class="w-1/2">
                <div class="text-coolGray-400 text-sm">{{text.billingAddress}}</div>
                <div class="flex flex-col">
                  <div class="flex flex-row gap-1">
                    <div v-if="billingFirstName">{{billingFirstName}}</div>
                    <div v-if="billingLastName">{{billingLastName}}</div>
                  </div>
                  <div v-if="billingCompanyName">{{billingCompanyName}}</div>
                  <div v-if="billingStreetAddress1 && billingStreetAddress1 !== 'EMPTY'">{{billingStreetAddress1}}</div>
                  <div v-if="billingStreetAddress2">{{billingStreetAddress2}}</div>
                  <div class="flex flex-row gap-1">
                    <div v-if="billingPostalCode && billingPostalCode !== '12345'">{{billingPostalCode}}</div>
                    <div v-if="billingCity && billingCity !== 'EMPTY'">{{billingCity}}</div>
                  </div>
                  <div v-if="billingCityArea">{{billingCityArea}}</div>
                  <div v-if="billingCountry">{{billingCountry}}</div>
                  <div v-if="billingCountryArea">{{billingCountryArea}}</div>
                </div>
              </div>
            </div>

            <!-- versand -->
            <div class="font-light mt-5">
              <div v-if="selectedService" class="text-coolGray-400 text-sm">{{selectedService.charAt(0).toUpperCase() + selectedService.slice(1)}}</div>
              <div v-if="selectedShippingMethodName" class="font-light">{{selectedShippingMethodName}}</div>
              <div v-if="selectedService == 'abholung'" class="font-light">
                <p>{{text.kron}}</p>
                <p>{{text.kron2}}</p>
              </div>
              <div v-if="selectedService == 'exw'" class="font-light">
                <p>{{text.exwInfo}}</p>
              </div>
            </div>

            <!-- umwelt -->
            <div v-if="cartTotal.trees > 0" class="font-light mt-5">
              <div class="text-coolGray-400 text-sm">{{text.environment}}</div>
              <div v-if="cartTotal.trees == 1" class="font-light">{{text.trees1sing}} {{cartTotal.trees}} {{text.trees2sing}}</div>
              <div v-else-if="cartTotal.trees > 0" class="font-light">{{text.trees1plur}} {{cartTotal.trees}} {{text.trees2plur}}</div>
            </div>

            <!-- vat id -->
            <div v-if="metaVatId" class="flex flex-row w-full justify-between items-center font-light mt-5">
              <div>{{text.vatID}}</div>
              <div>{{metaVatId}}</div>
            </div>

            <!-- zwischensumme -->
            <div class="flex flex-row w-full justify-between items-center font-light mt-5">
              <div>{{text.subtotal}}</div>
              <div>{{cartTotal.total}}</div>
            </div>

            <!-- rabatt -->
            <div v-if="discountCode && discountAmount" class="flex flex-row w-full justify-between items-center font-light mt-1">
              <div>{{text.discount}} "{{discountCode}}"</div>
              <div>-{{formatPrice(discountAmount)}}</div>
            </div>

            <!-- versandkosten -->
            <div class="flex flex-row w-full justify-between items-center font-light mt-1">
              <div>{{text.shippingCost}}</div>
              <div>{{formatPrice(shippingCost)}}</div>
            </div>

            <!-- gesamt ohne steuern-->
            <div class="flex flex-row w-full justify-between items-center font-light mt-1">
              <div>{{text.total}}</div>
              <div>{{formatPrice(checkoutAmount)}}</div>
            </div>

            <!-- gesamt mit steuern -->
            <div v-if="channel == 'b2b' && checkoutTotalIncludesTaxes" class="flex flex-row w-full justify-between items-center font-light mt-1">
              <div>{{text.totalTaxes}}</div>
              <div>{{formatPrice(checkoutAmountWithTaxes)}}</div>
            </div>

            <!-- mwst -->
            <div v-if="channel == 'b2c'" class="font-light text-xs text-coolGray-700 mt-1">{{text.taxInfo}}</div>

            <input v-if="paymentMethod !== 'Bei Abholung' || paymentMethod !== 'Rechnung'" type="submit"
              :value="[createPaymentIsFetching || mollieIsFetching ? text.redirect : text.payNow]"
              class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
              :class="{'animate-pulse' : createPaymentIsFetching || mollieIsFetching}"
              @click="paymentMethod = 'Mollie'"/>

            <input v-if="paymentMethod !== 'Bei Abholung' && channel == 'b2b'" type="submit"
              :value="[createPaymentIsFetching || mollieIsFetching ? text.redirect : text.payLater]"
              class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
              :class="{'animate-pulse' : createPaymentIsFetching || mollieIsFetching}"
              @click="paymentMethod = 'Rechnung'"/>

            <input v-if="selectedService == 'abholung'" type="submit"
              :value="[completeCheckoutIsFetching ? text.redirect : text.payAtPickup]"
              class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"
              :class="{'animate-pulse' : completeCheckoutIsFetching}"
              @click="paymentMethod = 'Bei Abholung'"/>

          </form>

          <div v-if="step07Errors?.length > 0" class="mt-2 font-light text-base text-red-600">
            {{step07Errors}}
          </div>

          <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="goBack()">
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back}}</div>
          </div>

        </div>
        <!-- STEP 07 -->

      </transition>

      <div class="w-full h-60 md:hidden"></div>
    </div>
    <!-- RIGHT -->

    <div v-else class="w-full md:w-1/2 min-h-screen px-8 md:px-0 h-full flex flex-row justify-center items-center">


      <div v-if="fetchMollie && !checkoutCompleted" class="flex flex-row">
      <div class="animate-spin mr-3">
        <svg class="w-6 h-6 text-coolGray-900 transform -rotate-90" viewBox="0 0 120 120">
          <circle class="opacity-25" cx="60" cy="60" r="54" fill="none" stroke="currentColor" stroke-width="12"/>
          <circle cx="60" cy="60" r="54" fill="none" stroke="currentColor" stroke-width="12" stroke-dasharray="339.292" stroke-dashoffset="250.000" stroke-linecap="round"/>
        </svg>
      </div>
      <div class="font-light">{{text.orderProcessing}}</div>
      </div>

      <div v-else>
        <div v-if="step07Errors?.length > 0" class="font-light text-base text-red-600">
          {{step07Errors}}
        </div>
        <div v-else>
          <div class="font-bold text-3xl">{{text.orderCompleted}}</div>
          <div class="font-light mt-2">
            <p>{{text.thankYou}}</p>
            <p>{{text.orderInfo}}</p>
          </div>

          <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="leaveCheckout()">
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.backHome}}</div>
          </div>

        </div>
      </div>
    </div>

    <!-- COMPONENTS -->
    <checkoutCompleteMutation />
    <checkoutBillingAddressUpdate />
    <addCouponCodeMutation />
    <addressValidationQuery />
    <checkoutPaymentCreateMutation />
    <createCheckoutMutation />
    <recheckQuantitiesQuery />
    <removeCouponCodeMutation />
    <setShippingMethodMutation />
    <shippingAddressUpdateMutation />
    <updateOrderMetadataMutation />
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import cartLines from '../components/cartLines.vue';
import { getFunctions, httpsCallable } from "firebase/functions";

import showHide from '../mixins/showHide.vue';
import formatPrice from '../mixins/formatPrice.vue';

import checkoutCompleteMutation from '../graphql/saleor/checkoutCompleteMutation.vue';
import checkoutBillingAddressUpdate from '../graphql/saleor/checkoutBillingAddressUpdate.vue';
import addCouponCodeMutation from '../graphql/saleor/addCouponCodeMutation.vue';
import addressValidationQuery from '../graphql/saleor/addressValidationQuery.vue';
import checkoutPaymentCreateMutation from '../graphql/saleor/checkoutPaymentCreateMutation.vue';
import createCheckoutMutation from '../graphql/saleor/createCheckoutMutation.vue';
import recheckQuantitiesQuery from '../graphql/saleor/recheckQuantitiesQuery.vue';
import removeCouponCodeMutation from '../graphql/saleor/removeCouponCodeMutation.vue';
import setShippingMethodMutation from '../graphql/saleor/setShippingMethodMutation.vue';
import shippingAddressUpdateMutation from '../graphql/saleor/shippingAddressUpdateMutation.vue';
import updateOrderMetadataMutation from '../graphql/saleor/updateOrderMetadataMutation.vue';

export default {
  name: 'Checkout',
  components: { cartLines, checkoutCompleteMutation, checkoutBillingAddressUpdate, addCouponCodeMutation, addressValidationQuery,
                checkoutPaymentCreateMutation, createCheckoutMutation, recheckQuantitiesQuery, removeCouponCodeMutation,
                setShippingMethodMutation, shippingAddressUpdateMutation, updateOrderMetadataMutation },
  mixins: [ showHide, formatPrice ],


  computed: {
    //graphql
    createCheckout(){
      return this.$store.state.data.createCheckout;
    },
    createCheckoutisFetching(){
      return this.$store.state.data.createCheckoutisFetching;
    },
    recheckQuantities(){
      return this.$store.state.data.recheckQuantities;
    },
    recheckQuantitiesIsFetching(){
      return this.$store.state.data.recheckQuantitiesIsFetching;
    },
    addCoupon(){
      return this.$store.state.data.addCoupon;
    },
    addCouponIsFetching(){
      return this.$store.state.data.addCouponIsFetching;
    },
    removeCoupon(){
      return this.$store.state.data.removeCoupon;
    },
    removeCouponIsFetching(){
      return this.$store.state.data.removeCouponIsFetching;
    },
    updateShipping(){
      return this.$store.state.data.updateShipping;
    },
    updateShippingIsFetching(){
      return this.$store.state.data.updateShippingIsFetching;
    },
    updateBilling(){
      return this.$store.state.data.updateBilling;
    },
    updateBillingIsFetching(){
      return this.$store.state.data.updateBillingIsFetching;
    },
    setShippingMethod(){
      return this.$store.state.data.setShippingMethod;
    },
    setShippingMethodIsFetching(){
      return this.$store.state.data.setShippingMethodIsFetching;
    },
    createPayment(){
      return this.$store.state.data.createPayment;
    },
    createPaymentIsFetching(){
      return this.$store.state.data.createPaymentIsFetching;
    },
    completeCheckout(){
      return this.$store.state.data.completeCheckout;
    },
    completeCheckoutIsFetching(){
      return this.$store.state.data.completeCheckoutIsFetching;
    },
    updataMetadata(){
      return this.$store.state.data.updataMetadata;
    },
    validation(){
      return this.$store.state.data.validation;
    },
    //data
    checkoutTotalIncludesTaxes(){
      return this.$store.state.data.checkoutTotalIncludesTaxes;
    },
    showCartInCheckout(){
      return this.$store.state.data.showCartInCheckout;
    },
    auth(){
      return this.$store.state.data.auth;
    },
    userDetails(){
      return this.$store.state.data.userDetails?.me;
    },
    currentLang(){
      return this.$store.state.lang.currentLang;
    },
    translatedCountries(){
      return this.$store.state.lang.countries[this.$store.state.lang.currentLang];
    },
    text(){
      return this.$store.state.lang.checkout[this.$store.state.lang.currentLang];
    },
    countries(){
      return this.$store.state.data.countries;
    },
    discountCode(){
      return this.$store.state.data.discountCode;
    },
    discountAmount(){
      return this.$store.state.data.discountAmount;
    },
    checkoutCompleted(){
      return this.$store.state.data.checkoutCompleted;
    },
    checkoutToken(){
      return this.$store.state.data.checkoutToken;
    },
    checkoutAmount(){
      return this.$store.state.data.checkoutAmount;
    },
    checkoutAmountWithTaxes(){
      return this.$store.state.data.checkoutAmountWithTaxes;
    },
    shippingCost(){
      return this.$store.state.data.shippingCost;
    },
    availableShippingMethods(){
      return this.$store.state.data.availableShippingMethods;
    },

    cart(){
      return this.$store.state.data.cart;
    },
    channel(){
      return this.$store.state.data.channel;
    },
    cartTotal(){
      var total = 0;
      var totalQty = 0;
      var trees = 0;
      this.cart.items.forEach(function(item){
        total = total + item.total;
        totalQty = totalQty + item.qty;
        var metaIndex = item.meta.findIndex(x => x.key == 'trees');
          if(metaIndex != -1){
            var metaVal = item.meta[metaIndex].value;
            trees = trees + metaVal * item.qty;
          }
      });
      return {total: this.formatPrice(total), qty: totalQty, trees: trees};
    }
  },

  data(){
    return {
      //Navigation
      currentStep: 0,
      lastStep:[0],
      direction: 0,

      //Coupon Codes
      couponCode: null,
      couponCodeErrors: null,

      //VAT Id
      vatId: null,
      metaVatId: null,
      vatIdIsFetching: false,
      vatIdErrors: null,

      //Step 01 User data
      customerEmail: null,
      customerEmailCC: null,
      shippingFirstName: null,
      shippingLastName: null,
      updateInsufficientStock: false,
      step01Errors: null,

      //Step 02 Shipping, pickup or EXW
      selectedService: null,
      step02Errors: null,

      //Step 03 Shipping details
      shippingCompanyName: null,
      shippingStreetAddress1: null,
      shippingStreetAddress2: null,
      shippingPostalCode: null,
      shippingCity: null,
      shippingCityArea: null,
      shippingCountry: 'DE',
      shippingCountryArea: null,
      abweichendeAdresse: null,
      step03Errors: null,

      //Step 04 Billing details
      billingFirstName: null,
      billingLastName: null,
      billingCompanyName: null,
      billingStreetAddress1: null,
      billingStreetAddress2: null,
      billingPostalCode: null,
      billingCity: null,
      billingCityArea: null,
      billingCountry: 'DE',
      billingCountryArea: null,
      step04Errors: null,

      //Step 05 Shipping options
      selectedShippingMethod: null,
      selectedShippingMethodName: null,
      step05Errors:null,

      //Step 06 Marketing
      step06Errors: null,
      emailMarketing: false,
      acceptsGoodies: false,
      marketingMaterial: false,

      //Step 07 Order summary
      step07Errors: null,
      fetchMollie: false,
      mollieIsFetching: false,
      paymentMethod: null,

      //Step 07 Checkout completed
      step07Errors: null,
    }
  },

  watch: {
    currentStep(){
      if(this.currentStep == 6){
        this.$store.commit('hideNav', true);
      } else {
        this.$store.commit('hideNav', false);
      }
    },
    shippingCountry(){
      this.$store.commit('changeCountryCode', this.shippingCountry);
      let itemIndex = this.countries.findIndex(x => x.value == this.shippingCountry);
      if (itemIndex !== -1) {
        let taxSetting = this.countries[itemIndex].taxes
        if (!this.metaVatId) {
          this.$store.commit('setCheckoutTotalIncludesTaxes', taxSetting);
        }
      }
    },
    billingCountry(){
      this.$store.commit('changeCountryCode', this.shippingCountry);
    },
  },

  beforeMount(){
    if(this.$route.query.fetchMollie){
      this.fetchMollie = this.$route.query.fetchMollie;
      if (window.localStorage.rewhiteCheckoutToken) {
          console.log("checkout token found");
          this.$store.commit('setCheckoutToken',  window.localStorage.rewhiteCheckoutToken);
        }
      this.getMolliePayment();
    } else {
      this.$store.commit('resetCheckout');
    }
  },

  mounted(){
    if(this.userDetails){
      this.customerEmail = this.userDetails.email;
      this.shippingFirstName = this.userDetails.firstName;
      this.shippingLastName = this.userDetails.lastName;

      if(this.userDetails.metadata.some(m => m.key == 'cc')){
        this.customerEmailCC = this.userDetails.metadata[this.userDetails.metadata.findIndex(x => x.key == 'cc')].value;
      }

      if(this.userDetails.defaultShippingAddress){
        this.shippingFirstName = this.userDetails.defaultShippingAddress.firstName || null;
        this.shippingLastName = this.userDetails.defaultShippingAddress.lastName || null;
        this.shippingCompanyName = this.userDetails.defaultShippingAddress.companyName || null;
        this.shippingStreetAddress1 = this.userDetails.defaultShippingAddress.streetAddress1 || null;
        this.shippingStreetAddress2 = this.userDetails.defaultShippingAddress.streetAddress2 || null;
        this.shippingPostalCode = this.userDetails.defaultShippingAddress.postalCode || null;
        this.shippingCity = this.userDetails.defaultShippingAddress.city || null;
        this.shippingCityArea = this.userDetails.defaultShippingAddress.cityArea || null;
        this.shippingCountry = this.userDetails.defaultShippingAddress.country.code || 'DE';
        this.shippingCountryArea = this.userDetails.defaultShippingAddress.countryArea || null;

        if(JSON.stringify(this.userDetails.defaultShippingAddress) !== JSON.stringify(this.userDetails.defaultBillingAddress)){
          this.abweichendeAdresse = true;
        }
      }

      if(this.userDetails.defaultBillingAddress){
        this.billingFirstName = this.userDetails.defaultBillingAddress.firstName || null;
        this.billingLastName = this.userDetails.defaultBillingAddress.lastName || null;
        this.billingCompanyName = this.userDetails.defaultBillingAddress.companyName || null;
        this.billingStreetAddress1 = this.userDetails.defaultBillingAddress.streetAddress1 || null;
        this.billingStreetAddress2 = this.userDetails.defaultBillingAddress.streetAddress2 || null;
        this.billingPostalCode = this.userDetails.defaultBillingAddress.postalCode || null;
        this.billingCity = this.userDetails.defaultBillingAddress.city || null;
        this.billingCityArea = this.userDetails.defaultBillingAddress.cityArea || null;
        this.billingCountry = this.userDetails.defaultBillingAddress.country.code || 'DE';
        this.billingCountryArea = this.userDetails.defaultBillingAddress.countryArea || null;
      }


    }
    if(this.cart.items.length == 0){
      this.$router.push({ name: 'Cart' });
    }
  },

  methods: {

  //----------------------------------------------//
  //------------------ANIMATIONS------------------//
  //----------------------------------------------//


  flowEnter(el, done){
    anime({
      targets: el,
      easing: 'easeInOutCubic',
      duration: 350,
      opacity: [0,1],
      complete: done
    });
  },

  flowLeave(el, done){
    anime({
      targets: el,
      easing: 'easeInSine',
      duration: 350,
      opacity: [1,0],
      complete: done
    });
  },


  //----------------------------------------------//
  //------------------METHODS---------------------//
  //----------------------------------------------//

    submitStep01(){
      console.log("Step 01 completed, validating input");

      //update cart items quantity if insufficient stock
      if(this.updateInsufficientStock){
        this.cart.items.forEach(function(item){
          if(item.quantityAvailable){
            item.qty = item.quantityAvailable;
            item.quantityAvailable = null;
            item.total = item.price * item.qty;
          }
        });
        this.$store.commit('saveCart');
        this.updateInsufficientStock = false;
      }

        var lines = [];
        this.cart.items.forEach(function(item){
          lines.push({ quantity: item.qty, variantId: item.id});
        });
        this.createCheckout({
          channel: this.channel,
          email: this.customerEmail.toLowerCase(),
          lines: lines,
          firstName: this.shippingFirstName,
          lastName: this.shippingLastName,
          languageCode: this.currentLang
        })
        .then(result => {
          if (result.error) {
            console.log(result.error.message);
            this.step01Errors = result.error;
          } else {
            console.log(result.data);
            var errors = result.data.checkoutCreate.checkoutErrors;
            if(errors.length == 0){
              if (window.localStorage.rewhiteCheckoutToken) {
                window.localStorage.removeItem('rewhiteCheckoutToken');
              }
              this.$store.commit('setCheckoutAmount', result.data.checkoutCreate.checkout.totalPrice.gross.amount);
              const b2bWithTaxes = (Math.round(result.data.checkoutCreate.checkout.totalPrice.gross.amount *1.19 * 100) / 100).toFixed(2);
              this.$store.commit('setCheckoutAmountWithTaxes', b2bWithTaxes);
              window.localStorage.setItem('rewhiteCheckoutToken', result.data.checkoutCreate.checkout.token);
              this.$store.commit('setCheckoutToken', result.data.checkoutCreate.checkout.token);
              this.lastStep.push(this.currentStep);
              this.currentStep = this.currentStep + this.direction;
              window.scrollTo({top: 0, behavior: 'smooth'});
            } else {
              if(errors.some(e => e.code == 'INSUFFICIENT_STOCK')){
                this.step01Errors = this.text.errorStock;
                this.handleIsufficientStock();
              } else {
                this.step01Errors = errors;
              }
            }
        }
        });
    },

    handleIsufficientStock(){
      this.updateInsufficientStock = true;

      var ids = [];
      this.cart.items.forEach(function(item){
        ids.push(item.id)
      });

      this.recheckQuantities({
        channel: this.channel,
        ids: ids
      })
      .then(result => {
        console.log(result.data);
        var that = this;
        result.data.productVariants.edges.forEach(function(item){
          var itemIndex = that.cart.items.findIndex(x => x.id === item.node.id);
          if(itemIndex !== -1){
            if(item.node.quantityAvailable < that.cart.items[itemIndex].qty){
              that.cart.items[itemIndex].quantityAvailable = item.node.quantityAvailable;
            }
          }
        });
      });
    },

    submitCouponCode(){
      console.log("Validating coupon code");
      let code = this.couponCode;
      if (code === 're:felsenfest') code = 'refels';
      if (code === 'climbingteamNRW') code = 'clmbngtmnrw';
      if(this.checkoutToken){
        this.addCoupon({
          token: this.checkoutToken,
          promoCode: code,
        })
        .then(result => {
          if (result.error) {
            console.log(result.error.message);
            this.couponCodeErrors = result.error;
          } else {
            console.log(result.data);
            var errors = result.data.checkoutAddPromoCode.checkoutErrors;
            if(errors.length == 0){
              this.$store.commit('setDiscount', {code: result.data.checkoutAddPromoCode.checkout.voucherCode, amount: result.data.checkoutAddPromoCode.checkout.discount.amount});
              this.couponCodeErrors = null;
              this.$store.commit('setCheckoutAmount', result.data.checkoutAddPromoCode.checkout.totalPrice.gross.amount);
              const b2bWithTaxes = (Math.round(result.data.checkoutAddPromoCode.checkout.totalPrice.gross.amount *1.19 * 100) / 100).toFixed(2);
              this.$store.commit('setCheckoutAmountWithTaxes', b2bWithTaxes);
            } else {
              this.couponCodeErrors = errors;
            }
          }
        });
      } else {
        this.couponCodeErrors = this.text.errorCoupon
      }
    },

    removeCouponCode(){
      console.log("Removing discount code");
      this.removeCoupon({
        token: this.checkoutToken,
        promoCode: this.discountCode
      })
      .then(result => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            console.log(result.data);
            var errors = result.data.checkoutRemovePromoCode.checkoutErrors;
            if(errors.length == 0){
              this.$store.commit('setDiscount', {code: result.data.checkoutRemovePromoCode.checkout.voucherCode, amount: result.data.checkoutRemovePromoCode.checkout.discount.amount});
              this.$store.commit('setCheckoutAmount', result.data.checkoutRemovePromoCode.checkout.totalPrice.gross.amount);
              const b2bWithTaxes = (Math.round(result.data.checkoutRemovePromoCode.checkout.totalPrice.gross.amount *1.19 * 100) / 100).toFixed(2);
              this.$store.commit('setCheckoutAmountWithTaxes', b2bWithTaxes);
            } else {
              console.log(errors);
            }
          }
      });
    },

    submitStep02(){
      console.log("Step 02 completed, validating input")
      if (this.selectedService == 'exw') {
        this.$store.commit('setCheckoutTotalIncludesTaxes', false);
      } else {
        if (!this.metaVatId) {
          this.$store.commit('setCheckoutTotalIncludesTaxes', true);
        }
      }

      if(this.selectedService == 'abholung' || this.selectedService == 'exw'){
      this.setShippingMethod({
          token: this.checkoutToken,
          shippingMethodId: "U2hpcHBpbmdNZXRob2Q6OTI="
        })
        .then(result => {
          if (result.error) {
            console.log(result.error.message);
            this.step02Errors = result.error;
          } else {
            console.log(result.data);
            var errors = result.data.checkoutShippingMethodUpdate.checkoutErrors;
            if(errors.length == 0){
              this.$store.commit('setCheckoutAmount', result.data.checkoutShippingMethodUpdate.checkout.totalPrice.gross.amount);
              const b2bWithTaxes = (Math.round(result.data.checkoutShippingMethodUpdate.checkout.totalPrice.gross.amount *1.19 * 100) / 100).toFixed(2);
              this.$store.commit('setCheckoutAmountWithTaxes', b2bWithTaxes);
              this.$store.commit('setShippingCost', result.data.checkoutShippingMethodUpdate.checkout.shippingPrice.gross.amount);
              this.lastStep.push(this.currentStep);
              this.currentStep = this.currentStep + this.direction;
              window.scrollTo({top: 0, behavior: 'smooth'});
            } else {
              this.step02Errors = errors;
            }
        }
        });
      } else if(this.selectedService == 'versand'){
        this.lastStep.push(this.currentStep);
        this.currentStep = this.currentStep + this.direction;
        window.scrollTo({top: 0, behavior: 'smooth'});
      } else {
         this.step02Errors = this.text.errorService
        }
    },

    submitStep03(){
      console.log("Step 03 completed, validating input")
      this.updateShipping({
        token: this.checkoutToken,
        firstName: this.shippingFirstName,
        lastName: this.shippingLastName,
        companyName: this.shippingCompanyName || '',
        streetAddress1: this.shippingStreetAddress1 || '',
        streetAddress2: this.shippingStreetAddress2 || '',
        city: this.shippingCity || '',
        cityArea: this.shippingCityArea || '',
        postalCode: this.shippingPostalCode || '',
        country: this.shippingCountry,
        countryArea: this.shippingCountryArea || ''
      })
      .then(result => {
          if (result.error) {
            console.log(result.error.message);
            this.step03Errors = result.error;
          } else {
            console.log(result.data);
            var errors = result.data.checkoutShippingAddressUpdate.errors;
            if(errors.length == 0){
              if(!this.abweichendeAdresse){
                this.setBillingAddressEqualShippingAddress();
              } else {
                //this.getShippingMethods(this.checkoutToken);
                this.getShippingMethodsNew(result.data.checkoutShippingAddressUpdate.checkout.availableShippingMethods);
              }
            } else {
              this.step03Errors = errors;
            }
        }
        });
    },

    setBillingAddressEqualShippingAddress(){
      this.updateBilling({
        token: this.checkoutToken,
        firstName: this.shippingFirstName,
        lastName: this.shippingLastName,
        companyName: this.shippingCompanyName || '',
        streetAddress1: this.shippingStreetAddress1 || '',
        streetAddress2: this.shippingStreetAddress2 || '',
        city: this.shippingCity || '',
        cityArea: this.shippingCityArea || '',
        postalCode: this.shippingPostalCode || '',
        country: this.shippingCountry,
        countryArea: this.shippingCountryArea || ''
      })
      .then(result => {
          if (result.error) {
            console.log(result.error.message);
            this.step03Errors = result.error;
          } else {
            console.log(result.data);
            var errors = result.data.checkoutBillingAddressUpdate.errors;
            if(errors.length == 0){
              //this.getShippingMethods(this.checkoutToken);
              this.getShippingMethodsNew(result.data.checkoutBillingAddressUpdate.checkout.availableShippingMethods);
            } else {
              this.step03Errors = errors;
            }
          }
      });
    },

    getShippingMethodsNew(shippingMethods){
      this.lastStep.push(this.currentStep);
      this.currentStep = this.currentStep + this.direction;
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.$store.commit('setAvailableShippingMethods', shippingMethods);
    },


    submitStep04(){
      console.log("Step 04 completed, validating input")
      this.updateBilling({
        token: this.checkoutToken,
        firstName: this.billingFirstName  || '',
        lastName: this.billingLastName  || '',
        companyName: this.billingCompanyName || '',
        streetAddress1: this.billingStreetAddress1 || '',
        streetAddress2: this.billingStreetAddress2 || '',
        city: this.billingCity || '',
        cityArea: this.billingCityArea || '',
        postalCode: this.billingPostalCode || '',
        country: this.billingCountry,
        countryArea: this.billingCountryArea || ''
      })
      .then(result => {
          if (result.error) {
            console.log(result.error.message);
            this.step04Errors = result.error;
          } else {
            console.log(result.data);
            var errors = result.data.checkoutBillingAddressUpdate.errors;
            if(errors.length == 0){
              //this.getShippingMethods(this.checkoutToken);
               this.getShippingMethodsNew(result.data.checkoutBillingAddressUpdate.checkout.availableShippingMethods);
            } else {
              this.step04Errors = errors;
            }
        }
        });
    },

    submitVatId(){
     if(this.checkoutToken){
       if(this.vatId.startsWith('DE')){
        this.vatIdErrors = this.text.errorVatID;
      } else {
        this.vatIdIsFetching = true;
        const functions = getFunctions();
        const validateVatId = httpsCallable(functions, 'validateVatId');
        validateVatId({
          vatId: this.vatId
        })
        .then((result) => {
          this.vatIdIsFetching = false;
          if(result.data.data.valid){
            this.vatIdErrors = this.text.errorVatID2;
            console.log("valid")
            this.metaVatId = this.vatId;
            this.$store.commit('setCheckoutTotalIncludesTaxes', false);
          } else {
            this.vatIdErrors = this.text.errorVatID3 + this.vatId + this.text.errorVatID4;
          }
        });
      }
     } else {
      this.vatIdErrors = this.text.errorVatID5;
     }
    },

    submitStep05(){
      console.log("Step 05 completed, validating input");
      if(this.selectedShippingMethod){
        this.setShippingMethod({
          token: this.checkoutToken,
          shippingMethodId: this.selectedShippingMethod
        })
        .then(result => {
          if (result.error) {
            console.log(result.error.message);
            this.step05Errors = result.error;
          } else {
            console.log(result.data);
            var errors = result.data.checkoutShippingMethodUpdate.checkoutErrors;
            if(errors.length == 0){
              this.$store.commit('setCheckoutAmount', result.data.checkoutShippingMethodUpdate.checkout.totalPrice.gross.amount);
              const b2bWithTaxes = (Math.round(result.data.checkoutShippingMethodUpdate.checkout.totalPrice.gross.amount *1.19 * 100) / 100).toFixed(2);
              this.$store.commit('setCheckoutAmountWithTaxes', b2bWithTaxes);
              this.$store.commit('setShippingCost', result.data.checkoutShippingMethodUpdate.checkout.shippingPrice.gross.amount);
              this.lastStep.push(this.currentStep);
              this.currentStep = this.currentStep + this.direction;
              window.scrollTo({top: 0, behavior: 'smooth'});
            } else {
              this.step03Errors = errors;
            }
        }
        });
      } else {
        this.step05Errors = this.text.errorShippingMethod
      }
    },

    submitStep06(){
      console.log("Step 06 completed, validating input");
      this.lastStep.push(this.currentStep);
      this.currentStep = this.currentStep + this.direction;
      window.scrollTo({top: 0, behavior: 'smooth'});
    },

    submitStep07(){
      console.log("Step 07 completed, validating input");
      this.createPayment({
        token: this.checkoutToken,
        gateway: 'mirumee.payments.dummy',
        gatewayToken: 'none',
        amount: this.checkoutAmount
      })
      .then(result => {
        if (result.error) {
          console.log(result.error.message);
          this.step07Errors = result.error;
        } else {
          console.log(result.data);
          var errors = result.data.checkoutPaymentCreate.paymentErrors;
          if(errors.length == 0){
            if(this.paymentMethod == 'Mollie'){
              this.createMolliePayment();
            } else {
              this.createCheckoutMetadata();
              this.completeSaleorCheckout();
            }
          } else {
            this.step07Errors = errors;
          }
      }
      });
    },

    createMolliePayment(){
      this.mollieIsFetching = true;
      console.log("creating Mollie payment..");
      const functions = getFunctions();
      const mollieCreatePayment = httpsCallable(functions, 'mollieCreatePayment');
      var mollieAmount = 0;
      if(this.checkoutTotalIncludesTaxes){
        if (this.channel == "b2c") {
          mollieAmount = (Math.round(this.checkoutAmount * 100) / 100).toFixed(2);
        } else {
          mollieAmount = this.checkoutAmountWithTaxes;
        }
      } else {
        mollieAmount = (Math.round(this.checkoutAmount * 100) / 100).toFixed(2);
      }
      var mollieLocale = 'de_DE';
      if(this.currentLang !== 'DE'){
        mollieLocale = 'en_US';
      }
      mollieCreatePayment({
        value: mollieAmount,
        locale: mollieLocale,
        description: this.text.mollieInfo
      })
        .then((result) => {
          console.log(result);
          if (window.localStorage.rewhitePayment) {
            window.localStorage.removeItem('rewhitePayment');
          }
          this.createCheckoutMetadata();
          window.localStorage.setItem('rewhitePayment', result.data.id);
          window.location.replace(result.data._links.checkout.href);
        })
        .catch((error) => {
          console.log(error);
          this.mollieIsFetching = false;
        });
    },

    createCheckoutMetadata(){
      let total = 0;
      if(this.checkoutTotalIncludesTaxes){
        if (this.channel == "b2c") {
          total = (Math.round(this.checkoutAmount * 100) / 100).toFixed(2);
        } else {
          total = this.checkoutAmountWithTaxes;
        }
      } else {
        total = (Math.round(this.checkoutAmount * 100) / 100).toFixed(2);
      }
      var metadata = {
        customerEmailCC: this.customerEmailCC,
        vatId: this.metaVatId,
        totalWithTaxes: total,
        selectedService: this.selectedService,
        paymentMethod: this.paymentMethod,
        emailMarketing: this.emailMarketing,
        acceptsGoodies: this.acceptsGoodies,
        marketingMaterial: this.marketingMaterial
      };
      window.localStorage.setItem('rewhiteMetadata', JSON.stringify(metadata));
    },

    getMolliePayment(){
      console.log("getMolliePayments")
      const functions = getFunctions();
      const mollieGetPayment = httpsCallable(functions, 'mollieGetPayment');
      mollieGetPayment({
        payment: {
          id: window.localStorage.rewhitePayment
        }
      })
        .then((result) => {
            console.log(result)
            if(result.data.status == 'paid'){
              this.completeSaleorCheckout();
            } else {
              this.fetchMollie = false;
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },

    completeSaleorCheckout(){
      this.completeCheckout({
        token:this.checkoutToken,
      })
      .then(result => {
        if (result.error) {
          console.log(result.error.message);
          this.step07Errors = result.error;
        } else {
          var errors = result.data.checkoutComplete.checkoutErrors;
            if(errors.length == 0){
              window.localStorage.removeItem('rewhiteCheckoutToken');
              window.localStorage.removeItem('rewhitePayment');

              window.localStorage.setItem('rewhiteCart', '');
              this.$store.commit('resetCheckout');
              this.$store.commit('resetCart');
              console.log(result.data);
              this.updataMetadata({
                id: result.data.checkoutComplete.order.id,
                customerEmailCC: JSON.parse(window.localStorage.rewhiteMetadata).customerEmailCC || '-',
                totalWithTaxes: JSON.parse(window.localStorage.rewhiteMetadata).totalWithTaxes || '-',
                vatId: JSON.parse(window.localStorage.rewhiteMetadata).vatId || '-',
                selectedService: JSON.parse(window.localStorage.rewhiteMetadata).selectedService || '-',
                paymentMethod: JSON.parse(window.localStorage.rewhiteMetadata).paymentMethod || '-',
                emailMarketing: JSON.parse(window.localStorage.rewhiteMetadata).emailMarketing || '-',
                acceptsGoodies: JSON.parse(window.localStorage.rewhiteMetadata).acceptsGoodies || '-',
                marketingMaterial: JSON.parse(window.localStorage.rewhiteMetadata).marketingMaterial || '-'
              })
              .then(result => {
                if (result.error) {
                  console.log(result.error.message);
                  this.step07Errors = result.error;
                } else {
                  console.log(result.data);
                  var errors = result.data.updateMetadata.metadataErrors;
                  if(errors.length == 0){
                    console.log("CHECKOUT COMPLETED")
                    this.$store.commit('setCheckoutCompleted', true);
                  } else {
                    this.step07Errors = errors;
                  }
                }
              });
            } else {
              this.step07Errors = errors;
            }
        }
      });
    },

    leaveCheckout(){
      this.fetchMollie = false;
      this.$store.commit('setCheckoutCompleted', false);
      this.$router.push({ name: 'Home' });
    },

    goBack(){
      var rest = this.lastStep;
      var last = this.lastStep.pop();
      this.lastStep = rest;
      this.currentStep = last;
    }

  }
}
</script>

<style>
  input:-webkit-autofill {
   transition: background-color 5000s ease-in-out 0s;
}
</style>
